





































































































































































































import Estimate from '@/components/dashboard/Estimate.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { Estimate },
  name: 'RatesListComponent',
  methods: {
    openEstimateDialog() {
      (this as any).$refs.estimatedialog.open();
    }
  }
});
