






import RatesList from '@/components/rates/RatesList.vue'
import Vue from 'vue'
export default Vue.extend({
  components: { RatesList },
    name: "ShippingRatesPage",
    metaInfo(){
      return  {
      title: "Rates List - Our Nation Express",
    };
    }
    
})
