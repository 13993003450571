















































































import { parseInt } from 'lodash';
import Vue from 'vue';
export default Vue.extend({
  name: 'Estimate',
  data() {
    return {
      dialog: false,
      weightAmount: '' as any,
      checkbox: false,
      costAmount: '' as any
    };
  },
  computed: {
    ratesCalulation(): number {
      let rate = 0;

      const input = parseInt(Number(this.weightAmount).toFixed(0));
      const cost = parseInt(Number(this.costAmount).toFixed(0));

      if (input) {
        const beforeRate = input * 4.5;
        if (cost > 100) {
          const percentage = (40 / 100) * cost;
          rate = beforeRate + percentage;
        } else {
          rate = beforeRate;
        }
      }
      return rate;
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
});
